import Vue from 'vue'
import VueRouter from 'vue-router'
import Audience from '@/views/audience.vue'
import Author from '@/views/author.vue'
import Record from '@/views/record.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'home',
    // component: Audience
    component: Author
  },
  {
    path: '/audience',
    name: 'audience',
    component: Audience
  },
  {
    path: '/record',
    name: 'record',
    component: Record
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  routes
})

export default router
