<template>
    <div class="home">
      <!-- muted -->
      <video
        id="player-container-id"
        style="width: 100%; height: 100%"
        preload="auto"
        playsinline
        webkit-playsinline
        x5-playsinline
      ></video>
 
      <div class="local_stream_wrap">
        <div id="local_stream"></div>
      </div>
      <!-- <div id="local_video"></div> -->
  
      <!-- <div style="position: fixed; bottom: 100px; left: 30px; z-index: 9999">
        <van-button @click="initPushUrl" type="success">连麦</van-button>
      </div> -->
    </div>
  </template>
  
  <script>
  const livePusher = new TXLivePusher();
  import TCPlayer from "tcplayer.js";
  import { getRecordUrl } from "@/api/live-api";
  
  export default {
    components: {},
    data() {
      return {
        isSupported: true,
        cameraDeviceId: null,
        context: null,
        pullQuery: {
          sessionId: "",
        },
        client: null,
        userId: "",
        localStream: {},
        playUrl: ''
      };
    },
    mounted() {
      this.pullQuery.sessionId = this.getUrlValue("sessionId");
      // this.playUrl = 'https://1307294375.vod2.myqcloud.com/a3c5a50bvodcq1307294375/cd1394105576678020866544273/playlist.m3u8'
      this.context = TCPlayer("player-container-id", {
        autoplay: true,
        preload: "auto",
        controls: true,
        controlBar: {
          QualitySwitcherMenuButton: true,
          fullscreenToggle: true,
          volumePanel: true,
          playToggle: true,
        },
        webrtcConfig: {
          connectRetryCount: 3,
          connectRetryDelay: 1,
          showLog: true,
        },
      }); // player-container-id 为播放器容器 ID，必须与 html 中一致
      this.context.ready(() => {
        this.initPullUrl();
      });
      this.context.on("error", (error) => {
        // 做一些处理
        console.warn("错误信息", error);
        uni.postMessage({
          data: {
            errmsg: "当前直播已结束",
            code: 500,
          },
        });
      });
      this.context.on("fullscreenchange", (res) => {
        // 做一些处理
        uni.postMessage({
          data: {
            res,
            code: 200,
          },
        });
      });
    },
    methods: {
      // 获取推流地址
      async initPushUrl() {
        // const res = await getPushUrl({
        //   id: this.pullQuery.id,
        //   userId: this.userId,
        //   role: "audience",
        //   type: "webrtc",
        // });
        // console.log("推流地址", res);
        this.startLive(res);
      },
      startLive(url) {
        TXLivePusher.checkSupport().then((data) => {
          // 是否支持WebRTC
          if (data.isWebRTCSupported) {
            this.isSupported = true;
            console.log("WebRTC Support");
          } else {
            this.isSupported = false;
            console.log("WebRTC Not Support");
          }
  
          // 是否支持H264编码
          if (data.isH264EncodeSupported) {
            console.log("H264 Encode Support");
            this.isSupported = true;
  
            // console.log("livePusher", livePusher);
  
            // livePusher.setRenderView("local_stream");
            // livePusher.videoView.muted = true;
            // livePusher.videoView.objectFit = "fill";
            // 设置视频质量
            // livePusher.setVideoQuality("1080p");
            // 设置音频质量
            // livePusher.setAudioQuality("standard");
            // 自定义设置帧率
            // livePusher.setProperty("setVideoFPS", 25);
            // 设置分辨率
            // livePusher.setProperty('setVideoResolution', { width: 1920, height: 1080 });
  
            // 采集完摄像头和麦克风之后自动推流
            Promise.all([
              livePusher.startCamera(),
              livePusher.startMicrophone(),
              // livePusher.startScreenCapture({ audio: true }),
            ])
              .then((res) => {
                // this.$toast(res[2]);
                uni.postMessage({
                  data: {
                    msg: "开启直播",
                  },
                });
                livePusher.startPush(url);
                // livePusher.startPush('webrtc://184331.push.tlivecloud.com/live/anchor_1688751695521693698_1665954160288321537?txSecret=f8dbf03585000a694bfb30243c42a87f&txTime=64D36447');
              })
              .catch((err) => {
                console.log(err); // 没找到设备
              });
          } else {
            console.log("H264 Encode Not Support");
            this.isSupported = false;
          }
        });
      },
      async initPullUrl() {
        // this.context.src('http://zb.huiris.com/live/anchor_1688751695521693698_1665954160288321537.flv'); // url 播放地址
        const res = await getRecordUrl(this.pullQuery);
        // console.warn("开始观看", res);
  
        // const cdnUrl = `http://${CDNURL}/live/user_stream_${this.pullQuery.uid}.m3u8`;
        // const cdnUrl = `http://${CDNURL}/live/1400811816_${+this.pullQuery.id.slice(0, 4)}_${this.pullQuery.uid}.m3u8`
        // const cdnUrl = `http://zb.huiris.com.txlivecdn.com/live/1400811816_${+this.pullQuery.id.slice(0, 4)}_${this.pullQuery.uid}_main.m3u8`
        // console.log('cdnUrl', cdnUrl);
        this.context.src(res.record); // url 播放地址
        uni.postMessage({
          data: {
            msg: res,
          },
        });
        // this.context.src(cdnUrl); // url 播放地址
      },
  
      getUrlValue(value) {
        // 从第一个?开始，且不包括#之后，并截取掉?的部分
        var query = location.search.substring(1);
        // 从#开始的部分，并转换成数组
        var hash = location.hash.split("?");
        // query和hash均没有参数
        if (!query && hash.length < 2) {
          return "";
        }
        // 先取query部分的参数进行匹配
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
          var pair = vars[i].split("=");
          if (pair[0] == value) {
            return pair[1];
          }
        }
        // query没有参数，或者有参数但没找到，则取hash部分的参数
        if (!hash[1]) {
          return "";
        }
        vars = hash[1].split("&");
        for (var i = 0; i < vars.length; i++) {
          var pair = vars[i].split("=");
          if (pair[0] == value) {
            return pair[1];
          }
        }
        return "";
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  * {
    margin: 0;
    padding: 0;
  }
  .home {
    width: 100vw;
    height: 100vh;
  }
  #local_video {
    position: fixed;
    right: 30px;
    top: 30px;
    &::v-deep {
      video {
        // height: 100vh !important;
        // border: 1px solid blue !important;
        width: 200px !important;
        height: 200px !important;
        flex: 1;
        object-fit: cover !important;
      }
    }
  }
  #local_stream {
    width: 150px;
    height: 150px;
  }
  .local_stream_wrap {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
  }
  .end-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    width: 80px;
    height: 30px;
    background-color: #ff7a03;
    border-radius: 30px;
    z-index: 99;
    color: #fff;
  }
  </style>
  