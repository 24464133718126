import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import TRTC from 'trtc-js-sdk';
// import { CDNStreaming, PublishMode } from 'trtc-sdk-v5/plugins/cdn-streaming';
// const trtc = TRTC.create({ plugins: [CDNStreaming] });
import { Toast, Button, Dialog, NoticeBar } from 'vant';
import 'tcplayer.js/dist/tcplayer.min.css';
// import TRTC from 'trtc-sdk-v5';
Vue.use(Toast);
Vue.use(Button);
Vue.use(Dialog);
Vue.use(NoticeBar);
// const trtc = TRTC.create();

Vue.config.productionTip = false
Vue.prototype.$trtc = TRTC

// Vue.prototype.$trtc = trtc
// Vue.prototype.$TRTC = TRTC

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
