import request from "@/request/http";

// 获取推流地址
export const getPushUrl = (params) =>
  request.post("/api/live/getLivePushUrl", params);

// 获取拉流地址
export const getPullUrl = (params) =>
  request.post("/api/live/getLivePullUrl", params);
// 混流地址
export const mixStreamPost = (params) =>
  request.post("/api/live/startMCUMixTranscode", params);
// 配置表信息 获取直播标清 高清 超清
export const systemInfoPost = (params) =>
  request.post("/api/configure/selectConfigure", {});
// 获取回放地址
export const getRecordUrl = (params) =>
  request.get("/api/liveSession/liveSessionInfo", { params });
