import axios from "axios";
import { Toast } from "vant";
const instance = axios.create({
    // baseURL: "/api",
    // baseURL: "https://sdwh.huiris.com/sdwh-app",
  // baseURL: "https://d38119n991.imdo.co",
  baseURL: "https://w.hmz.cn/sdwh-app",

  timeout: 6000,
  
});

// 添加请求拦截器
instance.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    config.headers["Content-Type"] = 'application/x-www-form-urlencoded'
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    const res = response.data;
    if (response.status == 200) {
      if (res.code == 200) {
        return Promise.resolve(res.result);
      } else {
        Toast(res.message);

        return Promise.reject(res);
      }
    } else {
      Toast(res.message);
      return Promise.reject(res);
    }
  },
  function (error) {
    Toast(error.message);
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export default instance;
