import { render, staticRenderFns } from "./record.vue?vue&type=template&id=3023f747&scoped=true&"
import script from "./record.vue?vue&type=script&lang=js&"
export * from "./record.vue?vue&type=script&lang=js&"
import style0 from "./record.vue?vue&type=style&index=0&id=3023f747&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3023f747",
  null
  
)

export default component.exports